<!-- @Author: xiaodongyu -->
<!-- @Date: 2022-08-10 15:22:24 -->
<!-- @Last Modified by: xiaodongyu -->
<!-- @Last Modified time: 2022-08-26 10:06:09 -->

<template>
    <div class="google-map" />
</template>

<script>
/* global google */
export default {
    name: 'GoogleMap',

    props: {
        zoom: {
            type: Number,
            default: 8
        },
        // 坐标：{lat: number, lng: number}
        position: {
            type: Object,
            required: true
        },
        title: {
            type: String,
            default: ''
        }
    },

    watch: {
        position(newPosition) {
            this.refresh(newPosition);
        }
    },

    mounted() {
        this.load();
    },

    methods: {
        async load() {
            const {Loader} = await import('@googlemaps/js-api-loader');
            const loader = new Loader({
                apiKey: 'AIzaSyAQXxk3YLDX05EHXMv9Sout6daef9u6PTo',
                version: 'weekly'
            });
            await loader.load();
            this.map = null;
            this.marked = [];
            this.refresh();
        },

        refresh(position = this.position) {
            const {zoom, title, $el} = this;
            if (!this.map) {
                this.map = new google.maps.Map($el, {
                    center: position,
                    zoom
                });
            } else {
                this.map.setCenter(position);
                this.map.setZoom(zoom);
            }

            const positionStr = Object.values(position).join('-');
            if (!this.marked.includes(positionStr)) {
                new google.maps.Marker({ // eslint-disable-line no-new
                    position,
                    map: this.map,
                    title
                });
                this.marked.push(positionStr);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.google-map {
    height: 400px;
}
</style>
